import dashboardServices from '../../../services/supplier/dashboard'
import { RECALL_CLASSIFICATIONS, RECALL_TYPE, THRESHOLD_COLOR_STATUS,OUTPUT_DATE_FORMAT, ANTICIPATED_DURATION, RECALL_TYPE_DASHBOARD_CARD } from '../../../misc/commons'
export default {
    data() {
        return {
            OUTPUT_DATE_FORMAT: OUTPUT_DATE_FORMAT,
            status: 'OPEN',
            perPage: 50,
            perPageEmit: 50,
            currentPage: 1,
            per_page: [10, 20, 30, 40, 50],
            totalItems: null,
            filterStatus: 'Open',
            filterEventCategory: 'All',
            showFilterEventCategory: true,
            listStatus: [
                {text: 'All', value: 'All'},
                {text: 'Draft', value: 'Draft'},
                {text: 'Open', value: 'Open'},
                {text: 'Closed', value: 'Closed'},
            ],
            listEventCategory: [
                {text: 'All', value: 'All'},
                {text: 'Recalls', value: 'RECALL'},
                {text: 'Supply Advisories', value: 'PRODUCT DISRUPTION'},
            ],
            items: [],
            recall_classifications: RECALL_CLASSIFICATIONS,
            recall_type: RECALL_TYPE_DASHBOARD_CARD,
            anticipated_duration: ANTICIPATED_DURATION,
            threshold_color_status: THRESHOLD_COLOR_STATUS,
            minHeight: {
                meta: 0,
                title: 0,
                ack: 0,
                metric: 0,
            }
        }
    },
    created() {
        if(this.$store.state.lastedRouteNotAuth.fullPath!=undefined)
        {
            return this.$router.push({ path: this.$store.state.lastedRouteNotAuth.fullPath })
        }
        if (this._checkPermissions(['Supplier_ViewDashboard'])){
            this.findRecallsCard()
        }
    },
    watch: {
        perPageEmit(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: val,
            })
            this.requestFetchData()
        },
        currentPage(val) {
            this.$emit('update:searchQuery', {
                pageNumber: val,
                pageSize: this.perPageEmit,
            })
            this.requestFetchData()
        },

    },
    methods: {
        findRecallsCard() {
            dashboardServices.findRecallsCard({
                pageNumber: this.currentPage,
                pageSize: this.perPage,
                sortField: null,
                sortDirection: null,
                status: this.filterStatus,
                eventCategory: this.filterEventCategory,
            }).then(resp => {
                if (!resp.error){
                    this.items = resp.data.d.c
                    if(resp.data.d.c.length>0)
                    {
                        this.showFilterEventCategory = resp.data.d.c[0].filterByPermission == 'All' ? true : false
                    }
                    this.items.forEach(el => {
                        el.indicator = Math.round(el.total > 0 ? el.completed / el.total * 100 : 0)
                    })
                    this.totalItems = resp.data.d.t
                    this.$nextTick(()=>{
                        this.calcHeight()
                    })
                }
            })
        },
        requestFetchData() {
            //error on server mode ( when not in page 1 and make filter, it will emit twice the request) => set timeout
            if (this.timer2) {
                clearTimeout(this.timer2)
                this.timer2 = null
            }
            this.timer2 = setTimeout(() => {
                this.findRecallsCard()
            }, 5)
        },
        DownloadRecallsCard() {

            dashboardServices.downloadRecallsCard({
                pageNumber: this.currentPage,
                pageSize: this.perPage,
                sortField: null,
                sortDirection: null,
                status: this.status,
            }).then(resp => {
                if (!resp.error){
                    this.items = resp.data.d.c
                    this.items.forEach(el => {
                        el.indicator = Math.round(el.total > 0 ? el.completed / el.total * 100 : 0)
                    })
                    this.totalItems = resp.data.d.t
                    this.$nextTick(()=>{
                        this.calcHeight()
                    })
                }
            })
        },
        statusChange(status) {
            this.status = status
            this.findRecallsCard()
        },
        downloadExcel(status) {
            this.status = status
            this.DownloadRecallsCard()
        },
        selectPerPage(perPage) {
            this.perPage = perPage
            this.perPageEmit = perPage
        },
        isActiveStatus(status) {
            return this.status == status ? 'btn btn-primary' : 'btn btn-secondary'
        },
        viewRecall(item) {
            if(this._checkPermissions(['Supplier_ManageRecalls','Supplier_ManageDisruption'])) {
                if(item.communicationType == 'RECALL')
                {

                    this.$router.push({ name: 'supp_Recall_ViewItem', params: { id: item.communicationId } })}
                else
                {
                    this.$router.push({ name: 'supp_Disruption_ViewItem', params: { id: item.communicationId } })
                }
            }
        },
        calcHeight() {
            let maxHeight = (type) => {
                var maxheight = 0
                for (const prop in this.$refs) {
                    for (let i = 0; i < this.items.length; i++) {
                        if (prop == type+i) {
                            var height = this.$refs[prop][0].clientHeight
                            maxheight = maxheight <= height ? height : maxheight
                        }
                    }
                }
                return maxheight
            }

            this.minHeight = {
                meta: maxHeight('meta'),
                title: maxHeight('title'),
                ack: Math.max(maxHeight('ack'), maxHeight('notack')),
                metric: Math.max(maxHeight('metric1'), maxHeight('metric2'), maxHeight('metric3')),
            }
        },
        badgeMsgClick(item) {
            if (item.first_conversation_id && item.first_conversation_id > 0 && this._checkPermissions(['Supplier_ManageRecalls'])) {
                this.$router.push({ name: 'supp_Recall_ViewItem', params: { id: item.communicationId }, query: {conId: item.first_conversation_id} })
            }
        }
    }
}